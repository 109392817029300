import React, { useRef, useEffect } from "react";
import DOMPurify from "dompurify";
import "./ChatRoom.css";
import useChatRoom from "../useChatRoom";
import AmplitudeHelper from "../analytics";

const ChatRoom = (props) => {
  const messagesEndRef = useRef(null);
  const waitingMessageRef = useRef(null);
  const textArea = useRef(null);
  const { roomId } = props.match.params;

  const {
    messages,
    inputLock,
    waitingMessage,
    sendMessage,
  } = useChatRoom(roomId);
  const [newMessage, setNewMessage] = React.useState("");

  const scrollToBottom = () =>
    messagesEndRef.current?.scrollIntoView({
      behavior: "auto",
      block: "nearest",
    });

  const scrollToWaiting = () =>
    waitingMessageRef.current?.scrollIntoView({
      behavior: "auto",
      block: "nearest",
    });

  const setFocus = () => {
    textArea.current.focus();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
      event.preventDefault();
    }
  };

  // detects for https:// and for gajigesa://  (deeplink uri-scheme)
  const urlRegex = /(https?:\/\/[^\s]+|gajigesa:\/\/\S+)/g;
  const deeplinkRegex = /gajigesa:\/\//;

  const parsedMessages = messages.map(message => {
    const parsedBody = message.body.replace(urlRegex, match => {
      message.openNewPage = true;
      if (deeplinkRegex.test(message.body)) {// for the case where on the login the deeplink
        message.openNewPage = false; // don't open new page (deeplink shouldnt open new page on pwa) (cta safe)
        if (roomId === 'null') match = "#" // on the open login page where employeeid is null
      }
      return `<a href="${match}" target="_blank" title="ChatWebLink">Click here!</a>`;
    });
    return { ...message, body: parsedBody };
  });

  useEffect(() => {
    scrollToBottom();
    setFocus();
  }, [messages]);

  useEffect(() => {
    AmplitudeHelper.logEvent("chatbot_initiated", { employeeId: roomId ?? null });
  }, [roomId])

  useEffect(() => {
    if (waitingMessage) scrollToWaiting();
  }, [waitingMessage]);

  useEffect(() => {
    setFocus();
  }, [inputLock]);

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = () => {
    let prompt = `${newMessage}.`;
    sendMessage(prompt);
    setNewMessage("");
  };
  const getAllowAttr = (openNewPage) => {
    if (openNewPage) return ['target', 'href'];
    return ['href'];
  }

  return (
    <div className="chat-room-container">
      <div className="messages-container">
        <ol className="messages-list">
          {parsedMessages.map((message, i) => (
            <li
              key={i}
              className={`message-item ${message.ownedByCurrentUser ? "my-message" : "received-message"
                }`}
              ref={messagesEndRef}
            >
              <div
                className="message-item-text"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(message.body, {
                    ADD_URI_SAFE_ATTR: ['href'], // Allow only the 'href' attribute
                    ALLOWED_ATTR: getAllowAttr(message.openNewPage)
                  }),
                }}
              ></div>
            </li>
          ))}
          {waitingMessage ? (
            <li ref={waitingMessageRef}>
              <div class="chat-bubble">
                <div class="typing">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>
            </li>
          ) : (
            <li></li>
          )}
        </ol>
      </div>
      <div className="input-container">
        <textarea
          value={newMessage}
          onChange={handleNewMessageChange}
          placeholder="Write message..."
          className="new-message-input-field"
          disabled={inputLock}
          onKeyDown={handleKeyDown}
          ref={textArea}
          autoFocus={true}
        />
        <button
          onClick={handleSendMessage}
          disabled={waitingMessage}
          className="send-message-button"
        >
          <img src="Vector.svg" alt="arrow-btn" />
        </button>
      </div>
    </div>
  );
};

export default ChatRoom;
