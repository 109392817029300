import { useCallback, useEffect, useRef, useState } from "react";

const useChatRoom = (roomId) => {
  const [messages, setMessages] = useState([]);
  const [waitingMessage, setWaitingMessage] = useState(true);
  const [inputLock, setInputLock] = useState(true);

  const socketRef = useRef();

  const sendMessage = useCallback(
    (messageBody) => {
      if (socketRef.current && socketRef.current.readyState === 1) {
        setInputLock(true);
        setWaitingMessage(true);
        socketRef.current.send(messageBody);

        const messageData = {
          body: messageBody,
          ownedByCurrentUser: true,
        };
        setMessages((messages) => [...messages, messageData]);
      }
    },
    [roomId]
  );

  useEffect(() => {
    const socket = new WebSocket(
      `${process.env.REACT_APP_SOCKET_SERVER_URL}/${roomId}/`
    );
    socketRef.current = socket;
    socket.addEventListener("open", () => {
      setWaitingMessage(true);
      const default_message = `Halo, saya butuh bantuan chat support`;
      sendMessage(default_message);
    });

    socket.addEventListener("message", (event) => {
      const incomingMessage = {
        body: event.data,
        ownedByCurrentUser: false,
      };
      setMessages((messages) => [...messages, incomingMessage]);
      setWaitingMessage(false);
      setInputLock(false);
    });

    return () => {
      socket.close();
    };
  }, [socketRef, sendMessage, roomId]);

  return { messages, sendMessage, inputLock, waitingMessage };
};

export default useChatRoom;
